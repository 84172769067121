import { ISnowtam } from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';
import React from 'react';
import { convertTime, tag } from '../InfoPage/TableCol/helpers';
import { IAftnMessageFC } from './AftnMessages';

export const SnowtamHeader: React.FC<IAftnMessageFC<ISnowtam>> = (props) => {
	const start = moment.utc(props.message.validity?.start);
	const end = moment.utc(props.message.validity?.end);
	const now = moment.utc();

	return (
		<div className={'aftnRow aftnHeader'} key={`snowtam_header_${props.index}`}>
			<div className={'aftnCol bold'}>{`${props.message.icao ?? ''}`}</div>
			<div className={'aftnCol col-3 col-mobile-0'}></div>
			<div className={'aftnCol'}>{tag(convertTime(start.toDate()), end.isBefore(now))}</div>
			{/* Use the end time and make BOTH tags RED if expired */}
			<div className={'aftnCol'}>
				{tag(convertTime(end.toDate()), end.isBefore(now))}
			</div>
		</div>
	);
};

export const SnowtamContent: React.FC<IAftnMessageFC<ISnowtam>> = (props) => (
	<div className={'aftnRow aftnContent noPadding'} key={`snowtam_content_${props.index}`}>
		<div className={'aftnCol italic'}>{stripSnowtam(props.message.rawInput)}</div>
	</div>
);

const stripSnowtam = (snowtam: string) => {
	// Remove all text until the word "SNOWTAM"
	return snowtam.substring(snowtam.indexOf('SNOWTAM'));
};
