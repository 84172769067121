import { EAftnTypes } from '@naviair-gl/node-shared-interfaces';
import { IAftnMessage, INotam, ISigmet, ISnowtam } from '@naviair-utm/node-shared-interfaces';
import React from 'react';
import { TMetarTaf } from '../../interfaces';
import { MetarTafContent, MetarTafContentMobile, MetarTafHeader } from './MetarTaf';
import { NotamContent, NotamHeader, NotamHeaderMobile } from './Notam';
import { SigmetContent, SigmetHeader } from './Sigmet';
import { SnowtamContent, SnowtamHeader } from './Snowtam';
import { Moment } from 'moment';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IAftnMessageFC<T = INotam | ISnowtam | ISigmet> {
	message: IAftnMessage<T>;
	index?: number; // Used to create index
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IAftnComponentProps {
	message: IAftnMessage | TMetarTaf;
	type: EAftnTypes;
	mobile?: boolean;
	index?: number;
}

/**
 * Returns the header for the provided AFTN message type
 * @param props @see IAftnComponentProps
 * @returns
 */
export const AftnHeader: React.FC<IAftnComponentProps> = (props) => {
	switch (props.type) {
		case 'notam':
			return props.mobile ? (
				<NotamHeaderMobile message={props.message as IAftnMessage<INotam>} key={`notam_header_${props.index}`} />
			) : (
				<NotamHeader message={props.message as IAftnMessage<INotam>} key={`notam_header_${props.index}`} />
			);
		case 'snowtam':
			return <SnowtamHeader message={props.message as IAftnMessage<ISnowtam>} key={`snowtam_header_${props.index}`} />;
		case 'metar_taf':
			return <MetarTafHeader {...(props.message as TMetarTaf)} key={`metar_taf_header_${props.index}`} />;
		case 'sigmet':
			return <SigmetHeader message={props.message as IAftnMessage<ISigmet>} key={`sigmet_header_${props.index}`} />;
		default:
			return <></>;
	}
};

export const AftnContent: React.FC<IAftnComponentProps> = (props) => {
	switch (props.type) {
		case 'notam':
			return <NotamContent message={props.message as IAftnMessage<INotam>} key={`notam_${props.index}`} />;
		case 'snowtam':
			return <SnowtamContent message={props.message as IAftnMessage<ISnowtam>} key={`snowtam_${props.index}`} />;
		case 'metar_taf':
			return props.mobile ? (
				<MetarTafContentMobile {...(props.message as TMetarTaf)} key={`metar_taf_${props.index}`} />
			) : (
				<MetarTafContent {...(props.message as TMetarTaf)} key={`metar_taf_${props.index}`} />
			);
		case 'sigmet':
			return <SigmetContent message={props.message as IAftnMessage<ISigmet>} key={`sigmet_header_${props.index}`} />;
		default:
			return <></>;
	}
};


/**
 * Format a date for the tags in the header of AFTN messages
 * @param date JS date
 */
export const headerTagDateFormatter = (date: Date):string => {
	const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
	// Return format as: DD MMM YY HH:MMZ, e.g. 24 MAY '24 13:37
	return `${date.getUTCDate()} ${months[date.getUTCMonth()]} '${date.getUTCFullYear().toString().substring(2)} ${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}Z`;
};
