import React from 'react';
import './styles.scss';
import { EScreenType } from '@naviair-gl/node-shared-interfaces';

export enum EBannerTypes {
	WARNING = 'WARNING',
	DANGER = 'DANGER',
	INFO = 'INFO',
}

interface IMapBanner {
	type: EBannerTypes,
	message: string,
	hide?: boolean,
	screenType: EScreenType,
}

export const MapBanner = (props: IMapBanner) => {
	if (!props.hide) {
		return (
			<div className={`mapBanner ${props.type} ${props.screenType}`}>
				<p>{props.message}</p>
			</div>
		);
	} else {
		return <></>;
	}
};
